import { getCookie } from "./cookies";

export const getFormatDate = (value: string) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const formatDate = new Date(value);
  const newDate = formatDate.getDate();
  const newMonth = months[formatDate.getMonth()];
  const newYear = formatDate.getFullYear();
  const newHour = formatDate.getHours();
  const newMinute = formatDate.getMinutes();
  return `${newDate} ${newMonth} ${newYear}, ${newHour}:${newMinute}`;
};

export interface ObjectType {
  [key: string]: any;
}

export const getInitials = (item: any) => {
  if (item) {
    item = item.split(" ");
    let nameInitials = item[0][0];
    if (item[1] && item[1][0]) {
      nameInitials += item[1][0];
    }
    return nameInitials;
  }
};

export const isLATDDR = () => {
  const islAT = getCookie("loginFlow") === "LAT";
  let roles = getCookie("roles") || "";
  roles = JSON.parse(roles) || [];
  const isDDR = roles?.includes("DD_REVIEW");
  return isDDR && islAT && roles.length >= 1;
};
