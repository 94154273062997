import React from 'react';
import { createStyles, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import CommentsForm from './CommentsForm';
import CommentsList from './CommentsList';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() =>
    createStyles({
        listWrapper: {
            paddingTop: "0px",
            paddingLeft: "0px"
        },
        formWrapper: {

        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            alignItems: "center",
            width: "500px",
            padding: "20px"
        }
    })
);

export default function CommentPopup(props: any) {
    const {
        id,
        onClose = () => {/*defaultValue */ },
        openConfirm,
        buttonText,
        onSubmit,
        commentsList = [],
        showForm,
        showList,
        onChange = () => {/*defaultValue */ },
        onEnter = () => {/*defaultValue */ }
    } = props;
    const onConfirmClose = () => {
        onClose()
    };
    const classes = useStyles();
    return (
        <Dialog
            open={openConfirm}
            onClose={onConfirmClose}
        >
            <div className={classes.header}>
                <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
                    Error Resolved Comment
                </div>
                <CloseIcon
                    htmlColor="#414169"
                    style={{ width: 16, height: 16, cursor: "pointer" }}
                    onClick={onConfirmClose}
                />
            </div>

            <DialogContent dividers className={showList ? classes.listWrapper : classes.formWrapper}>
                {showList && <CommentsList
                    commentsList={commentsList}
                />}
                {showForm && <CommentsForm
                    onSubmit={onSubmit}
                    showSenderAvatar={false}
                    placeholder="Enter text here"
                    avatarStyles=""
                    avatarText=""
                    id={id}
                    onChange={onChange}
                    onEnter={onEnter}
                    isTextAreaDisabled={false}
                    isButtonDisabled={false}
                    textAreaStyles=""
                    buttonIcon={<></>}
                    buttonText={buttonText}
                    buttonStyles=""
                    textAreaLable="Add your comment"
                />}
            </DialogContent>
        </Dialog>
    )
}