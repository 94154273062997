import { makeStyles, createStyles, Theme } from "@material-ui/core";
export const DashBoardStyles = makeStyles((theme: Theme) => {
  return createStyles({
    detailsBtn: {
      minWidth: "auto",
      justifyContent: "start",
      padding: 0
    },
    detailsLabel: {
      width: "70px",
      position: "relative"
    },
    detailsEndIcon: {
      position: "absolute",
      right: 0
    },
    subHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #e2e4f1"
    },
    searchInput: {
      backgroundColor: "#f7fafc",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      border: "1px solid #e9ecef",
      borderRadius: 30,
      width: 290
    },
    searchIcon: {
      padding: 8
    },
    totalCountWrap: {
      display: "flex",
      marginTop: 8
    },
    divider: {
      height: 16,
      margin: "0 6px",
      width: 2,
      backgroundColor: "#979797"
    },
    clearIcon: {
      backgroundColor: "transparent",
      border: "none"
    },
    table: {
      minWidth: 650
    },
    boxTableContainer: {
      margin: "16px 0",
      maxHeight: "calc(100vh - 110px)"
    },
    tableRowGrid: {
      display: "grid",
      gridTemplateColumns:
        "220px 200px 150px 145px 150px 120px 130px 120px 140px 1fr",
      "& .MuiTableCell-root.MuiTableCell-head": {
        display: "flex",
        alignItems: "center",
        padding: "10px 12px"
      },
      "& .MuiTableCell-root": {
        padding: "16px 12px"
      }
    },
    linkRoot: {
      border: "transparent",
      padding: 0,
      justifyContent: "flex-start",
      "&:hover": {
        border: "transparent",
        backgroundColor: "transparent"
      }
    },
    tableBody: {
      minHeight: "250px",
      display: "block"
    },
    resolvedCheckboxColor: {
      color: "#2ece89 !important"
    },
    checkboxBase: {
      padding: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem"
      }
    },
    resolveButton: {
      // marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      // marginTop: 10,
      // marginLeft: 16,
      // marginBottom: 5,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      },
      "&:disabled": {
        opacity: "0.5"
      }
    },
    headClass: {
      justifyContent: "center"
    },
    rowClass: {
      textAlign: "center"
    },
    commentIcon: {
      padding: "0px",
      marginTop: "-8px"
    }
  });
});
