import React from "react";
import {
    Box,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import { ObjectType } from '../helper/common';

export default function Table(props: any) {
    const { columnsData, rowsData, classes } = props;

    return (
        <MuiTable component="div" className={classes?.table ?? ""} stickyHeader>
            <TableHead component="div" className="sticky-top-zero">
                <TableRow component="div" className={classes?.tableRowGrid ?? ""}>
                    {
                        columnsData.map((col: ObjectType) =>
                            <TableCell component="div" key={col.id} className={col?.headClass ?? ""}>
                                {col.title}
                                {col?.filterList?.length &&
                                    <Box {...col.boxProps}>
                                        {
                                            col?.filterList?.map((filter: any) => filter.component)
                                        }
                                    </Box>
                                }
                            </TableCell>
                        )}
                </TableRow>
            </TableHead>
            <TableBody component="div" className={classes?.tableBody ?? ""}>
                {rowsData?.map((row: any) => (
                    <TableRow
                        key={row._id}
                        component="div"
                        className={classes?.tableRowGrid ?? ""}
                    >
                        {
                            columnsData.map((col: ObjectType) => (
                                <TableCell key={col.id} component="div" className={col?.rowClass ?? "" }>
                                    {col.renderCustom && col.renderCustom(row, classes)}
                                    {!col.renderCustom && row?.[col.id]}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                ))}
            </TableBody>
        </MuiTable>
    );
}


