const shadows = [
    'none',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)',
    '0 2px 12px 0 rgba(50, 50, 93, 0.21)'
];

export default shadows;