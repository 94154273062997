import React from "react";
import SortFilter from "../../components/SortFilter";
import ColumnSearchFilter from "../../components/ColumnSearchFilter";
import ColumnTypeFilter from "../../components/ColumnTypeFilter";
import AgeingFilter from "../../components/AgeingFilter";
import Link from "../../components/Link";
import ErrorDetailToolTip from "../../components/ErrorDetailToolTip";
import { isLATDDR, ObjectType } from "../../helper/common";
import { Link as RouterLink } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  Button,
  Checkbox,
  SvgIcon,
  IconButton,
  Badge
} from "@material-ui/core";
import AgeingIndicator from "../../components/AgeingIndicator";
import { ReactComponent as commentIcon } from "../../assets/images/commentIcon.svg";
import moment from "moment";
interface columnsInputs {
  onSort: Function;
  handleCheckBoxChange: Function;
  handleFilterToggle: Function;
  filtersOptions: ObjectType;
  filtersLoader: ObjectType;
  boxTablefilterConfig: ObjectType;
  boxTableSortConfig: ObjectType;
  handleSearchChange: Function;
  boxTableSearchConfig: ObjectType;
  classes: any;
  handleResolveCheckBox: Function;
  resolvedFiles: any;
  handleCommentClick: Function;
}

export const columns = (config: columnsInputs) => {
  const {
    onSort,
    handleCheckBoxChange,
    handleFilterToggle,
    filtersOptions,
    filtersLoader,
    boxTablefilterConfig,
    boxTableSortConfig,
    handleSearchChange,
    boxTableSearchConfig,
    classes,
    handleResolveCheckBox,
    resolvedFiles,
    handleCommentClick
  } = config;
  return [
    {
      id: "fileName.raw",
      title: "File Name",
      boxProps: { ml: "auto", lineHeight: 0 },
      rowClass: "fw-700 text-truncate",
      filterList: [
        {
          component: (
            <ColumnSearchFilter
              searchValue={boxTableSearchConfig?.["fileName.raw"]}
              columnName="fileName.raw"
              onSearch={handleSearchChange}
            />
          )
        },
        {
          component: (
            <SortFilter
              columnName="fileName.raw"
              onSort={onSort}
              activeSortState={boxTableSortConfig?.["fileName.raw"]}
            />
          )
        }
      ],
      renderCustom: (data: any) => {
        return <Link href={data.boxFilePath} title={data.fileName} />;
      }
    },
    {
      id: "folderPath",
      title: "Folder Path",
      rowClass: "fw-700 text-truncate",
      renderCustom: (data: any) => {
        return <Link href={data.boxFolderPath} title={data.boxFolderPath} />;
      }
    },
    {
      id: "conditionProcessEndTime",
      title: "Process Date",
      boxProps: { lineHeight: 0 },
      filterList: [
        {
          component: (
            <SortFilter
              columnName="conditionProcessEndTime"
              onSort={onSort}
              activeSortState={boxTableSortConfig?.conditionProcessEndTime}
            />
          )
        }
      ],
      renderCustom: (data: any) => {
        return (
          <>
            {" "}
            {moment(new Date(data.conditionProcessEndTime)).format(
              "DD MMM YYYY, HH:mm"
            )}
          </>
        );
      }
    },
    {
      id: "status",
      title: "Platform Condition Import",
      filterList: [
        {
          component: (
            <ColumnTypeFilter
              columnName="status.raw"
              handleCheckBoxChange={handleCheckBoxChange}
              handleFilterToggle={handleFilterToggle}
              options={filtersOptions?.["status.raw"]}
              isLoading={filtersLoader?.["status.raw"]}
              checked={boxTablefilterConfig?.["status.raw"]}
            />
          )
        }
      ]
    },
    {
      id: "originatorName",
      title: "Originator Name",
      filterList: [
        {
          component: (
            <ColumnTypeFilter
              columnName="originatorName.raw"
              handleCheckBoxChange={handleCheckBoxChange}
              handleFilterToggle={handleFilterToggle}
              options={filtersOptions?.["originatorName.raw"]}
              isLoading={filtersLoader?.["originatorName.raw"]}
              checked={boxTablefilterConfig?.["originatorName.raw"]}
            />
          )
        }
      ]
    },
    {
      id: "ageing",
      title: "Ageing",
      boxProps: { ml: "10px", lineHeight: 0 },
      rowClass: "fw-600",
      filterList: [
        {
          component: <AgeingFilter />
        },
        {
          component: (
            <SortFilter
              columnName="ageing"
              onSort={onSort}
              activeSortState={boxTableSortConfig?.ageing}
            />
          )
        }
      ],
      renderCustom: (data: any) => {
        return (
          <AgeingIndicator
            ageingStart={data.conditionProcessEndTime}
            resolvedAt={data.resolvedAt}
            resolvedBy={data.resolvedBy}
            status={data.errorResolved}
          />
        );
      }
    },
    {
      id: "errorDetails",
      title: "Error Details",
      rowClass: "fw-700",
      renderCustom: (data: any) => {
        return (
          <>
            {" "}
            <Button
              classes={{
                root: classes.detailsBtn,
                label: classes.detailsLabel,
                endIcon: classes.detailsEndIcon
              }}
              color="primary"
              component={RouterLink}
              to={`/box-details/${data?.boxFileId}`}
              target="_blank"
              endIcon={<OpenInNewIcon />}
            >
              {data?.errorResolved === "True"
                ? "Resolved"
                : `${data?.errorCount} Error`}
            </Button>
            {data?.errors?.length > 0 && (
              <ErrorDetailToolTip errorInfo={data?.errors} />
            )}
          </>
        );
      }
    },
    {
      id: "errorResolved",
      title: "Error Resolved ?",
      headClass: classes.headClass,
      rowClass: classes.rowClass,
      renderCustom: (data: any) => {
        return (
          <Checkbox
            disableRipple
            checked={!!resolvedFiles.get(data.boxFileId)?.checkFlag}
            onChange={e => {
              handleResolveCheckBox(e, data);
            }}
            disabled={data.resolvedBy === "Resolved by System" || !isLATDDR()}
            classes={{
              checked: classes.resolvedCheckboxColor,
              root: classes.checkboxBase
            }}
          />
        );
      }
    },
    {
      id: "resolvedBy.raw",
      title: "Resolved By",
      filterList: [
        {
          component: (
            <ColumnTypeFilter
              columnName="resolvedBy.raw"
              handleCheckBoxChange={handleCheckBoxChange}
              handleFilterToggle={handleFilterToggle}
              options={filtersOptions?.["resolvedBy.raw"]}
              isLoading={filtersLoader?.["resolvedBy.raw"]}
              checked={boxTablefilterConfig?.["resolvedBy.raw"]}
            />
          )
        },
        {
          component: (
            <SortFilter
              columnName="resolvedBy.raw"
              onSort={onSort}
              activeSortState={boxTableSortConfig?.["resolvedBy.raw"]}
            />
          )
        }
      ],
      renderCustom: (data: any) => {
        return data.resolvedBy ? data.resolvedBy : "Unresolved";
      }
    },
    {
      id: "comments",
      title: "Comments",
      headClass: classes.headClass,
      rowClass: classes.rowClass,
      renderCustom: (data: any) => {
        return (
          <IconButton
            disabled={
              !resolvedFiles.get(data.boxFileId)?.checkFlag ||
              (!isLATDDR() && !data.comment) ||
              data.resolvedBy === "Resolved by System"
            }
            classes={{ root: classes.commentIcon }}
            onClick={() => {
              handleCommentClick(data.boxFileId);
            }}
          >
            <Badge
              color="primary"
              variant="dot"
              invisible={!resolvedFiles.get(data.boxFileId)?.comment}
            >
              <SvgIcon
                component={commentIcon}
                viewBox={"0 0 16 16"}
                style={{ fontSize: "1.3rem" }}
              />
            </Badge>
          </IconButton>
        );
      }
    }
  ];
};
