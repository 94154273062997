export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const CLEAR_LOADER = "CLEAR_LOADER";
// export const STORE_API_ERROR = "STORE_API_ERROR";
// export const CLEAR_ERROR = "CLEAR_ERROR";

/**
 * @param loaderType can be used to store which api/component wants the loader to be showed.
 */
export function showLoader(loaderType: string): any {
  // console.log("called show loader", loaderType)
  return (dispatch: any) => {
    dispatch({
      type: SHOW_LOADER,
      payload: { loaderType }
    });
  };
}

/**
 * @param loaderType can be used to store which api/component wants the loader to be showed.
 */
export function hideLoader(loaderType: string): any {
  // console.log("called hide loader", loaderType)
  return (dispatch: any) => {
    dispatch({
      type: HIDE_LOADER,
      payload: { loaderType }
    });
  };
}

// export const storeAPIError = (errorConfigToSave: any) => {
//   return async (dispatch: any) => {
//     dispatch({ type: STORE_API_ERROR, payload: { errorConfigToSave } });
//   };
// };

// export const clearErrors = (data: {
//   errorId: string;
//   autoClose?: boolean;
//   closeAll?: boolean;
// }) => {
//   return async (dispatch: any) => {
//     dispatch({ type: CLEAR_ERROR, payload: { ...data } });
//   };
// };
