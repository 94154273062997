import React, { useEffect, useCallback, useState, useLayoutEffect, useRef } from "react";
import AppHeader from "../../components/AppHeader";
import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../rootReducer";
import {
  Typography,
  Container,
  Box,
  Grid,
  Divider,
  TableContainer,
  Paper,
  Button
} from "@material-ui/core";
import SearchFilter from "../../components/SearchFilter";
import Table from "../../components/Table";
import {
  hideLoader,
  showLoader
} from "../../helper/loaderAndException/loaderAndException.action";
import {
  updateBoxReportMonitoringInRedux,
  updateTotalErrorFile,
  updateTotalFile,
  updateBoxTableSortOptions,
  updateBoxTableSelectedFilters,
  updateBoxTableSearchConfig,
} from "./dashboardReport.action";
import {
  getBoxFileList,
  getFilterData,
  resolveFileErrors
} from "../../network/apiService";
import { columns } from './dashboardColumns';
import { getInitials, isLATDDR, ObjectType } from '../../helper/common';
import { LoaderAndExceptionsStore } from "../../helper/loaderAndException/loaderAndException.reducer";
import { DashBoardStyles } from './styles';
import { getCookie } from "../../helper/cookies";
import CommentPopup from "../../components/CommentPopup";
import moment from "moment";

const columnToBucketMap: ObjectType = {
  "status.raw": "statusBucket",
  "originatorName.raw": "originatorBucket",
  "resolvedBy.raw": "resolvedByBucket"
}

const filtersDataInitialState: ObjectType = {
  "status.raw": null,
  "originatorName.raw": null,
  "resolvedBy.raw": null
}

const Dashboard = () => {

  const classes = DashBoardStyles();
  const dispatch = useDispatch();
  const [filtersOptions, setFiltersOptions] = useState(filtersDataInitialState);
  const [filtersLoader, setFiltersLoader] = useState({ status: false, originatorName: false });
  const [resolvedFiles, setResolvedFiles] = useState(new Map());
  const [initiallyResolved, setinitiallyResolved] = useState(new Map());
  const [popupConfig, setPopupConfig] = useState<any>({ id: "", openConfirm: false });
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const { boxReportMonitoringResult: monitoringList, boxTablefilterConfig, boxTableSortConfig, totalFile, totalErrorFile, boxTableSearchConfig } = useSelector<RootState, any>(
    (state) => state.DashBoardReportStore
  );

  const { loaderState } = useSelector<RootState, LoaderAndExceptionsStore>(
    (state) => state.LoaderAndExceptionsStore
  );

  const scrollRef = useRef<HTMLDivElement>(null);

  const transformErrorData = (data: any, flag: boolean, removeComment?: boolean) => {
    const conditionErrorId = data.errors.map((it: any) => it.id);
    const fileId = data.boxFileId;
    let checkFlag = flag ? 1 : 0;
    const userId = getCookie("person_id");
    let comment = flag ? data.comment : "";
    comment  = removeComment ? "" : comment;
    return { conditionErrorId: conditionErrorId, partyId: userId, fileId: fileId, checkFlag: checkFlag, comment: comment }
  }

  useEffect(() => {
    const temp = new Map(
      monitoringList
        ?.filter((it: any) => it?.errorResolved === "True")
        ?.map((it: any) => [it?.boxFileId, transformErrorData(it, true)])
    );
    setinitiallyResolved(temp);

    setResolvedFiles((resolvedFiles) => {
      return new Map([...temp, ...resolvedFiles]);
    });
  }, [monitoringList]);

  const fetchBoxFileList = (index?: number) => {
    dispatch(showLoader("boxFileList"));
    const listView = getBoxFileList(boxTablefilterConfig, boxTableSortConfig, boxTableSearchConfig, index);
    listView
      .then((response: any) => {
        let transformedData = response.data?.response?.hits?.hits.map((fileData: any) => ({ ...fileData, ...fileData._source }));
        transformedData = index ? [...monitoringList, ...transformedData] : transformedData;
        dispatch(
          updateBoxReportMonitoringInRedux(transformedData)
        );
        dispatch(updateTotalFile(response.data?.response?.hits?.total.value));
        const errorFile = response.data?.response?.aggregations?.errorFiles?.buckets.reduce(
          (acc: number, current: any) => {
            if (current.key !== "True") {
              return acc + current["doc_count"]
            } else {
              return acc
            }
          }, 0);
        dispatch(updateTotalErrorFile(errorFile));
        dispatch(hideLoader("boxFileList"));
      })
      .catch((err: any) => {
        dispatch(hideLoader("boxFileList"));
        console.log("err", err);
        setShowWarningPopup(true);
      });
  }

  useLayoutEffect(() => {
    if (!scrollRef.current) return
    const scrollElem = scrollRef.current;
    scrollElem.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollElem) {
        scrollElem.removeEventListener('scroll', handleScroll);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxTablefilterConfig, boxTableSortConfig, boxTableSearchConfig, monitoringList, loaderState, totalFile]);

  useEffect(() => {
    fetchBoxFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxTablefilterConfig, boxTableSortConfig, boxTableSearchConfig]);

  const handleScroll = () => {
    if (!scrollRef.current) return
    if (
      Math.ceil(scrollRef.current.clientHeight + scrollRef.current.scrollTop) >= scrollRef.current.scrollHeight
      && !loaderState.has("boxFileList")
      && monitoringList.length !== totalFile
    ) {
      fetchBoxFileList(monitoringList?.length);
    }
  };

  const onSort = useCallback((config: { [key: string]: any[] }) => {
    dispatch(updateBoxTableSortOptions(config));
  }, [dispatch]);

  const handleCheckBoxChange = useCallback((config: { [key: string]: any[] }) => {
    const updatedSelectedFiltersConfig = { ...boxTablefilterConfig, ...config }
    dispatch(updateBoxTableSelectedFilters(updatedSelectedFiltersConfig));
  }, [boxTablefilterConfig, dispatch]);

  const handleSearchChange = useCallback((config: { [key: string]: string }) => {
    dispatch(updateBoxTableSearchConfig(config));
  }, [dispatch]);

  const handleFilterToggle = useCallback((columnName: string) => {
    const bucketKey = columnToBucketMap[columnName]
    const listView = getFilterData(columnName, bucketKey);
    setFiltersLoader({ ...filtersLoader, [columnName]: true });
    listView
      .then((response: any) => {
        setFiltersLoader({ ...filtersLoader, [columnName]: false });
        const distinctValue = response.data.response?.aggregations?.[bucketKey].buckets;
        setFiltersOptions({ ...filtersOptions, [columnName]: distinctValue });
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, [filtersOptions, filtersLoader]);

  const handleResolveCheckBox = useCallback((e: any, data: any) => {
    const fileId = data.boxFileId;
    const updatedData = new Map(resolvedFiles);
    if (!updatedData.has(fileId)) {
      updatedData.set(fileId, transformErrorData(data, true))
    } else if (initiallyResolved.has(fileId)) {
      updatedData.set(fileId, transformErrorData(data, !updatedData.get(fileId).checkFlag, true))
    } else {
      updatedData.delete(fileId)
    }
    setResolvedFiles(updatedData)
  }, [resolvedFiles, initiallyResolved]);

  const handleCommentClick = (id: string) => {
    const selected = monitoringList.find((it: any) => it.boxFileId === id);
    const body = selected?.comment && resolvedFiles.get(id)?.comment;
    const commentsList = [];
    if (body) {
      const header = selected.resolvedBy;
      const avatarInitials = getInitials(header);
      const footer = moment(new Date(selected.resolvedAt)).format("DD MMM YYYY, HH:mm");
      commentsList.push({
        header,
        footer,
        body,
        avatarInitials: avatarInitials?.toUpperCase()
      })
    }
    setPopupConfig({ id: id, openConfirm: true, showList: !!body, showForm: !body, commentsList })
  }

  const handleResolveFileErrors = () => {
    const requestBody: any[] = [];
    resolvedFiles.forEach((value: any, key: any, map: any) => {
      if (initiallyResolved.has(key)
        && value.checkFlag
        && (initiallyResolved.get(key).comment === resolvedFiles.get(key).comment)) {
        return;
      }
      requestBody.push(value);
    });
    if(!requestBody.length) return
    dispatch(showLoader("boxFileList"));
    resolveFileErrors(requestBody)
      .then(() => {
        setTimeout(()=>{
          fetchBoxFileList();
        },1000);
      })
      .catch((err)=>{
          dispatch(hideLoader("boxFileList"));
          console.log(err);
          setShowWarningPopup(true);
      })
  }

  const closePopup = () => {
    setPopupConfig({
      id: "",
      openConfirm: false
    })
  };

  const onSubmit = (text: string, id: string) => {
    const updatedData = new Map(resolvedFiles);
    const selected = updatedData.get(id);
    updatedData.set(id, { ...selected, comment: text });
    setResolvedFiles(updatedData);
    closePopup();
  }

  const boxColumns = columns({
    onSort,
    handleCheckBoxChange,
    handleFilterToggle,
    handleSearchChange,
    filtersOptions,
    filtersLoader,
    boxTablefilterConfig,
    boxTableSortConfig,
    boxTableSearchConfig,
    classes,
    handleResolveCheckBox,
    resolvedFiles,
    handleCommentClick
  });

  const closeWarningPopup = () =>{
    setShowWarningPopup(false)
  }

  return (
    <>
      <WarningModal
        isOpen={showWarningPopup}
        handleClose={closeWarningPopup}
        primaryBtnName="OK"
        header="Warning"
        body="Something went wrong. Please try again"
      />
      <CommentPopup
        onClose={closePopup}
        buttonText={"Done"}
        onSubmit={onSubmit}
        {...popupConfig}
      />
      <Box>
        <AppHeader />
        <Box pt={8}>
          <Box className={classes.subHeader}>
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="h4" component="h1">
                    Box Due Diligence Report Monitoring
                  </Typography>
                  <Typography variant="body1" className={classes.totalCountWrap}>
                    <Typography component="span" display="inline">
                      Total Files {totalFile}
                    </Typography>
                    <Divider className={classes.divider} orientation="vertical" />
                    <Typography component="span" display="inline" className="fw-600">
                      Open Error Files {totalErrorFile}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="end">
                    {/* <Button
                variant="contained"
                color="primary"
                startIcon={<RefreshIcon />}
              >
                Clear Filter
              </Button> */}
                    {isLATDDR() && <Button
                      variant="contained"
                      color="primary"
                      classes={{ root: classes.resolveButton }}
                      onClick={() => { handleResolveFileErrors() }}
                      disabled={resolvedFiles.size === 0}
                    >
                      Resolve File Errors
                    </Button>}
                    <Box width="290px">
                      <SearchFilter searchValue={boxTableSearchConfig?.all} columnName="all" onSearch={handleSearchChange} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box>
            <Container maxWidth="xl">
              <TableContainer ref={scrollRef} component={Paper} className={classes?.boxTableContainer ?? ""}>
                <Table columnsData={boxColumns} rowsData={monitoringList} classes={classes} />
              </TableContainer>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;

