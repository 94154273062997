import { Avatar, createStyles, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import tIcon from "../assets/images/t.svg"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            color: "#32325d",
            paddingTop: "0px"
        },
        inline: {
            display: 'inline',
        },
        avatarStyles: {
            width: "30px",
            height: "30px",
            fontSize: "1rem",
            background: "#9d5ce6",
            color: "white"
        },
        avatarWrapper: {
            minWidth: "44px"
        },
        oval: {
            width: "14px",
            height: "14px",
            margin: "1px 81px 11px 1px",
            padding: "4px 3px 2px",
            backgroundImage: "linear-gradient(139deg, #0fcbef 2%, var(--deep-sky-blue) 95%), linear-gradient(to bottom, #d8d8d8, #d8d8d8)"
        }
    }),
);

export default function CommentsList(props: any) {
    const { commentsList } = props;
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {commentsList.map((commentData: any) =>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar classes={{ root: classes.avatarWrapper }}>
                        <Avatar classes={{ root: classes.avatarStyles }}>
                            {commentData.avatarInitials}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText

                        primary={
                            <span style={{ display: "inline-flex" }}>
                                {commentData.header}
                                <img
                                    src={tIcon}
                                    alt="lat"
                                    style={{
                                        margin: "0px 5px 1px 5px"
                                    }}
                                />
                            </span>}
                        secondary={
                            <>
                                <p style={{ margin: "0.5rem 0", fontSize: "14px", color: "#32325d" }}>
                                    {commentData.body}
                                </p>
                                <p style={{ margin: "0", color: "#32325d" }}>
                                    {commentData.footer}
                                </p>
                            </>
                        }
                    />
                </ListItem>
            )}
        </List>
    )
}