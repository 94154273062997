import React, { useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { useSelector } from "react-redux";
import { Grid, makeStyles, createStyles, Theme } from "@material-ui/core";
import { RootState } from "../rootReducer";
import { LoaderAndExceptionsStore } from "../helper/loaderAndException/loaderAndException.reducer";
import toorakLogo from "../assets/images/toorakLogo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 999999999,
      backgroundColor: "rgba(65,65,105, 0.25)"
    }
  })
);
export const LoaderWithLogo = () => {
  return (
    <Grid className="loaderBody">
      <div className="e-loadholder">
        <div className="m-loader">
          <img
            src={toorakLogo}
            alt="left_img_trades"
            style={{ padding: 10 }}
            className="e-text"
          />
        </div>
      </div>
      <div id="particleCanvas-Blue" />
      <div id="particleCanvas-White" />
    </Grid>
  );
};

export const LoaderFB = (props: { open: boolean }) => {
  const { open } = props;
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open} data-testid="loader">
      <LoaderWithLogo />
    </Backdrop>
  );
};

export const Loader = () => {
  const [open, setOpen] = React.useState(false);

  const { loaderState } = useSelector<RootState, LoaderAndExceptionsStore>(
    state => state.LoaderAndExceptionsStore
  );

  useEffect(() => {
    let isLoading: boolean = false;
    if (loaderState.size > 0) {
      isLoading = true;
    }
    setOpen(isLoading);
  }, [loaderState]);

  return <LoaderFB open={open} />;
};
