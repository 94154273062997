import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { publicClient } from "@toorak/tc-common-fe-sdk";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { CssBaseline, createGenerateClassName } from "@material-ui/core";
import { lightTheme } from "./assets/styles/lightTheme";
import { getCookie } from "./helper/cookies";
import { rootStore } from "./rootReducer";
import { getConfig } from "./helper/config";
import { Loader, LoaderFB } from "./components/Loader";
import { AuthCallbackPage } from "./helper/auth";
import Dashboard  from "./views/dashboard/Dashboard";
import "./App.css";
const BoxDetailView = React.lazy(
  () => import("./views/boxDetailView/BoxDetailView")
);

publicClient.interceptors.request.use((config: any) => {
  // Add headers inside condition for internal API's
  if (config?.url?.match(getConfig().apiUrl)) {
    config.headers["Authorization"] = `Bearer ${getCookie("box_id_token")}`;
  }
  return config;
});

function App() {
  const history = createBrowserHistory();
  const store = rootStore(history);
  const configParams = getConfig();

  const Private = ({ component: Component, params }: any) => {
    if (!getCookie("box_id_token")) {
      window.location.href = configParams.platformLogOutUrl;
    }
    const auth = getCookie("box_id_token")?.length;
    return auth ? <Component {...(params || {})} /> : <Navigate to="/login" />;
  };

  const generateClassName = createGenerateClassName({
    productionPrefix: "box"
  });

  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <MuiThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Provider store={store}>
          <Suspense fallback={<LoaderFB open />}>
            <BrowserRouter>
              <Loader />
              <Routes>
                <Route path="/callBack" element={<AuthCallbackPage />} />
                <Route
                  path="/"
                  element={
                    <Private
                      component={Navigate}
                      params={{ to: "/dashboard" }}
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={<Private component={Dashboard} />}
                />
                <Route
                  path="/box-details/:fileId"
                  element={<Private component={BoxDetailView} />}
                />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
