import React from "react";

import { IconButton } from "@material-ui/core";

import { FilterSortIcon } from "../icon/FilterSortIcon";

interface SortFilterProps {
  columnName: string;
  activeSortState: string;
  onSort: Function;
}

enum SortOrder {
  ascending = "asc",
  descending = "desc"
}

const SortFilter = (props: SortFilterProps) => {
  const { columnName, activeSortState = SortOrder.ascending, onSort } = props;
  const onClick = () => {
    const orderValue = activeSortState === SortOrder.ascending ? SortOrder.descending : SortOrder.ascending;
    onSort({ [columnName]: orderValue });
  };

  return (
    <IconButton size="small" onClick={onClick}>
      <FilterSortIcon />
    </IconButton>
  );
};

export default SortFilter;
