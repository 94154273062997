import React, { useState } from "react";
import { Button, Grid, Avatar, createStyles, makeStyles, FormControl, FormLabel, TextField } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        formWrapper: {
            display: "flex",
            alignItems: "center",
            margin: "0 0 1rem"
        },
        avatar: {
            marginRight: "1rem"
        },
        formLabel: {
            marginBottom: "0.5rem"
        },
        asterix: {
            color: "red"
        },
        buttonStyles: {
            background: "#5566d6",
            color: "white"
        }
    })
);

export default function CommentsForm(props: any) {
    const {
        onSubmit,
        showSenderAvatar,
        placeholder = "Enter text here",
        avatarStyles = "",
        avatarText = "",
        id,
        onChange = () => {/*defaultValue */ },
        onEnter = () => {/*defaultValue */ },
        isTextAreaDisabled,
        textAreaStyles = {},
        buttonIcon = <></>,
        buttonText,
        buttonStyles = "",
        textAreaLable = ""
    } = props;
    const classes = useStyles();

    const [text, setText] = useState<string>("");
    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const val = e.target.value;
        setText(val);
        onChange(val);
    }
    const handleSubmit = () => {
        onSubmit(text, id)
    }
    return (
        <div
            data-testid="comment-form-container"
        >
            <div className={classes.formWrapper}>
                {showSenderAvatar &&
                    <Avatar className={`${classes.avatar} ${avatarStyles}`}>
                        {avatarText}
                    </Avatar>
                }
                <FormControl component="fieldset" fullWidth>
                    <FormLabel required classes={{ asterisk: classes.asterix, root: classes.formLabel }} >{textAreaLable}</FormLabel>
                    <TextField
                        rowsMax={5}
                        rows={3}
                        multiline
                        placeholder={placeholder}
                        value={text}
                        onChange={handleTextChange}
                        onKeyPress={onEnter}
                        disabled={isTextAreaDisabled}
                        classes={textAreaStyles}
                        name="comment-text"
                        id={`comment-text ${id}`}
                        variant={"outlined"}
                        type={"text"}
                    />
                </FormControl>
            </div>
            <Grid container>
                <Button
                    variant="contained"
                    disabled={text === ""}
                    startIcon={buttonIcon}
                    className={buttonStyles}
                    type="submit"
                    classes={{ root: classes.buttonStyles }}
                    onClick={handleSubmit}
                >
                    {buttonText}
                </Button>
            </Grid>
        </div>
    )
}