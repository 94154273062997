import React, { useRef } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles({
  iconButtonRoot: {
    fontSize: "16px",
    color: "#5566d6",
    marginLeft: "10px"
  },
  tooltipPaper: {
    maxWidth: "300px"
  },
  listRoot: {
    padding: "0"
  },
  listTextRoot: {
    margin: 0
  },
  listTextPrimary: {
    fontWeight: 600
  },
  popper: {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #fffefe  transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#fffefe  transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent #fffefe  transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #fffefe `,
      },
    },
  },
  tooltip: {
    backgroundColor: "#fff",
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
    margin: "0px 5px"
  },
  arrow: {
    color: "#fff"
  }
});

interface ErrorDetailToolTipProps {
  errorInfo: any;
}

const ErrorDetailToolTip = (props: ErrorDetailToolTipProps) => {
  const { errorInfo } = props;
  const classes = useStyles();
  const anchorRef = useRef(null);

  const distinctErrorCode = (value: any[]) => {
    return value
      .map((obj) => {
        return obj.errorCode;
      })
      .filter((item, index, arr) => {
        return arr.indexOf(item) === index;
      });
  };

  const filterData = (data: any[]) => {
    const errorCode = distinctErrorCode(data);
    const finalResponse: any = [];
    errorCode.forEach((item) => {
      const error = data.filter((ite) => {
        if (ite.errorCode === item) {
          return true;
        }
        return false;
      });
      const errorCodeObj = {
        title: error[0].errorCode,
        id: error[0].id,
        count: error.length,
        error: error
      };
      finalResponse.push(errorCodeObj);
    });
    return finalResponse;
  };

  return (
    <>
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        placement={"right"}
        arrow
        title={<>
          {filterData(errorInfo)?.map((item: any, index: number) => {
            return (
              <List classes={{ root: classes.listRoot }}>
                <ListItem>
                  <ListItemText
                    classes={{
                      root: classes.listTextRoot,
                      primary: classes.listTextPrimary
                    }}
                    primary={`${item.title} (${item.count})`}
                  ></ListItemText>
                </ListItem>
              </List>
            );
          })}
        </>}
      >
        <IconButton
          aria-label="info"
          size="small"
          classes={{
            root: classes.iconButtonRoot
          }}
          ref={anchorRef}
        >
          <InfoOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ErrorDetailToolTip;
