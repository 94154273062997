import React from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  MenuList,
  IconButton,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";

import { FilterFunnelIcon } from "../icon/FilterFunnelIcon";
import SearchFilter from "./SearchFilter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid rgb(255 255 255 / 22%)",
      backgroundColor: "rgb(15 14 14 / 22%)",
      color: "#fff",
      fontSize: 10,
      display: "flex",
      textAlign: "left",
      borderRadius: 8
    },
    appName: {
      width: 100
    },
    userAvatar: {
      width: 36,
      height: 36,
      backgroundColor: "#5e72e4",
      borderRadius: "50%"
    },
    menuRoot: {
      width: 275,
      marginTop: 22,
      padding: 20
    },
    iconMargin: {
      marginRight: 5
    },
    signOut: {
      padding: "20px 16px",
      borderTop: "1px solid #e2e4f1",
      display: "flex",
      alignCenter: "center",
      "& p": {
        marginLeft: 5
      }
    },
    menuList: {
      padding: 0
    },
    avatarWrap: {
      gap: "15px",
      padding: "20px 16px"
    },
    avatarRoot: {
      width: 36,
      height: 36,
      fontSize: 20,
      backgroundColor: "#002856"
    }
  })
);

interface ColumnSearchFilterProps {
  columnName: string;
  onSearch: any;
  searchValue: string;
}

const ColumnSearchFilter = (props: ColumnSearchFilterProps) => {
  const {
    columnName,
    onSearch = () => {
      /*defaultValue */
    },
    searchValue = ""
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FilterFunnelIcon filled={searchValue} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        <Paper
          classes={{
            root: classes.menuRoot
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
              className={classes.menuList}
            >
              <Box>
                <SearchFilter
                  searchValue={searchValue}
                  onSearch={onSearch}
                  columnName={columnName}
                />
              </Box>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default ColumnSearchFilter;
