import { createMuiTheme } from '@material-ui/core/styles';
import typography from "./typography";
import shadows from "./shadows";

export const lightTheme = createMuiTheme({
    palette: {
        text: {
            primary: "#32325d",
            secondary: "#8897aa",
            hint: "#5566d6"
        },
        background: {
            paper: "#ffffff",
            default: "#f8f9ff"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    WebkitFontSmoothing: 'antialiased',
                    height: "100%",
                    width: "100%"
                },
                body: {
                    height: "100%"
                },
                "#root": {
                    height: "100%"
                },
                "::-webkit-scrollbar": {
                    height: 10,
                    width: 10
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(206, 210, 229, 0.5)",
                    borderRadius: 13
                },
                "::-webkit-scrollbar-thumb": {
                    backgroundColor: "#ffffff",
                    backgroundClip: "padding-box",
                    border: "2px solid transparent",
                    borderRadius: 13,
                    boxShadow: "inset 0px 4px 0px 0px rgba(0,0,0,0)"
                }

            }
        },
        MuiTableCell: {
            root: {
                fontSize: 12,
                borderBottom: "1px solid #e9ecef",
                padding: 16
            },
            stickyHeader: {
                backgroundColor: "#f6f9fc"
            },
            head: {
                lineHeight: 1.5
            }
        },
        MuiAppBar: {
            root: {
                minHeight: 60
            },
            colorDefault: {
                backgroundImage: "linear-gradient(93deg, #0fcbef 4%, #1071ee 90%)",
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: "#ffffff",
                marginRight: 8,
                color: "#32325d",
                "&:hover": {
                    backgroundColor: "#dfe1e6"
                },
                "&.$Mui-disabled": {
                    backgroundColor: "#dfe1e6",
                    color: "#32325d !important",
                    opacity: "0.5",
                    pointerEvents: "unset !important"
                }
            },
            iconSizeMedium: {
                "&>*:first-child": {
                    fontSize: 14
                }
            }
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    },
    typography,
    shadows
});
