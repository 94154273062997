const typography = {
    htmlFontSize: 10,
    fontSize: 12,
    fontFamily: "'Open Sans', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body1: {
        fontWeight: 400,
        fontSize: 12
    },
    h1: {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: "-0.24px"
    },
    h2: {
        fontWeight: 600,
        fontSize: 22,
        letterSpacing: "-0.24px",
    },
    h3: {
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: "-0.06px",
    },
    h4: {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: "-0.06px",
    },
    h5: {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: "-0.05px",
    },
    h6: {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: "-0.05px",
    },
    body2: {
        fontWeight: 400,
        fontSize: 12
    },
    button: {
        textTransform: "none",
        fontSize: 12,
        fontWeight: 600
    },
};

export default typography;
