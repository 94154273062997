export const GET_BOX_REPORT_MONITORING_LIST = "GET_BOX_REPORT_MONITORING_LIST";
export const UPDATE_TOTAL_FILE = "UPDATE_TOTAL_FILE";
export const UPDATE_TOTAL_ERROR_FILE = "UPDATE_TOTAL_ERROR_FILE";
export const UPDATE_BOX_TABLE_FILTERS = "UPDATE_BOX_TABLE_FILTERS";
export const UPDATE_BOX_TABLE_SORT_OPTIONS = "UPDATE_BOX_TABLE_SORT_OPTIONS";
export const UPDATE_BOX_TABLE_SEARCH_OPTIONS =
  "UPDATE_BOX_TABLE_SEARCH_OPTIONS";

export function updateBoxReportMonitoringInRedux(data: any): any {
  return async (dispatch: any) => {
    dispatch({
      type: GET_BOX_REPORT_MONITORING_LIST,
      payload: data
    });
  };
}

export function updateTotalFile(data: any): any {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_TOTAL_FILE,
      payload: data
    });
  };
}

export function updateTotalErrorFile(data: any): any {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_TOTAL_ERROR_FILE,
      payload: data
    });
  };
}

export function updateBoxTableSelectedFilters(data: {}): any {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_BOX_TABLE_FILTERS,
      payload: data
    });
  };
}

export function updateBoxTableSortOptions(data: {}): any {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_BOX_TABLE_SORT_OPTIONS,
      payload: data
    });
  };
}

export function updateBoxTableSearchConfig(data: {}): any {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_BOX_TABLE_SEARCH_OPTIONS,
      payload: data
    });
  };
}
