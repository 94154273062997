import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCookie, setCookie } from "./cookies";
import {
  getCustomerRoleDetails,
  getOrganizationDetails,
  getRoleDetails,
  getToken,
  getUserRoles
} from "../network/apiService";
import {
  hideLoader,
  showLoader
} from "./loaderAndException/loaderAndException.action";
import { Loader } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { ObjectType } from "./common";

export async function fetchToken(tokenId: any) {
  let formBody: any = [];
  const loginObject: ObjectType = {
    client_id: 'login',
    grant_type: "refresh_token",
    refresh_token: tokenId,
  };
  Object.keys(loginObject).forEach((property) => {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(loginObject[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });
  formBody = formBody.join("&");
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
  return getToken(formBody, headers);
}

export const AuthCallbackPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(window.location.search);
  const isTokenAvailable = getCookie("box_id_token");
  const token: any = urlQuery.get("token");

  useEffect(() => {
    if (isTokenAvailable) {
      navigate(`/dashboard`);
    } else {
      fetchToken(token)
        .then((res: any) => {
          setCookie("box_id_token", res?.data?.access_token);
          const { email } = JSON.parse(atob(res?.data?.access_token?.split(".")[1]));
          setCookie("email", email);
          getRoles(email);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  async function getRoles(emailAddress: string) {
    dispatch(showLoader("auth"));
    const partInfo: any = await getRoleDetails(emailAddress);
    const partyId = partInfo.data.partyId;
    setCookie("firstName", partInfo.data.firstName);
    setCookie("lastName", partInfo.data.lastName);
    const customerRoleDetails: any = await getCustomerRoleDetails(partyId);
    const accountId = customerRoleDetails?.data[0].accountId;
    const organizationDetails: any = await getOrganizationDetails(accountId);
    const roles: any = await getUserRoles(accountId, partyId);
    const rolesObj = roles?.data?.data?.roles;
    const rolesArray: any[] = [];
    Object.keys(rolesObj).forEach(key => {
      rolesArray.push(rolesObj[key].roleName);
    });
    setCookie("roles", JSON.stringify(rolesArray));
    setCookie("organizationName", organizationDetails?.data.accountName);
    setCookie("org_id", customerRoleDetails?.data[0].accountId);
    setCookie("person_id", customerRoleDetails?.data[0].personId);
    dispatch(hideLoader("auth"));
    navigate(`/dashboard`);
    if (roles.data.data.roles[0].roleName.includes("LAT")) {
      setCookie("loginFlow", "LAT");
    }
  }

  return (
    <>
      <Loader />
    </>
  );
};

