import React from "react";

import {
  AppBar,
  Box,
  Button,
  Toolbar,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Avatar,
  Typography,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ToorakLogo } from "../icon/ToorakLogo";
import { SignOutIcon } from "../icon/SignOutIcon";
import { deleteAllCookies, getCookie } from "../helper/cookies";
import { getConfig } from "../helper/config";
import { ApplicationTilesIcon } from "../icon/ApplicationTilesIcon";
import { isLATDDR } from "../helper/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid rgb(255 255 255 / 22%)",
      backgroundColor: "rgb(15 14 14 / 22%)",
      color: "#fff",
      fontSize: 10,
      display: "flex",
      textAlign: "left",
      borderRadius: 8
    },
    appName: {
      width: 100
    },
    userAvatar: {
      width: 36,
      height: 36,
      backgroundColor: "#5e72e4",
      borderRadius: "50%"
    },
    menuRoot: {
      width: 275,
      marginTop: 5
    },
    iconMargin: {
      marginRight: 5
    },
    signOut: {
      padding: "20px 16px",
      borderTop: "1px solid #e2e4f1",
      display: "flex",
      alignItems: "center",
      "& p": {
        marginLeft: 5
      }
    },
    menuList: {
      padding: 0
    },
    avatarWrap: {
      gap: "15px",
      padding: "20px 16px"
    },
    avatarRoot: {
      width: 36,
      height: 36,
      fontSize: 20,
      backgroundColor: "#002856"
    }
  })
);

const AppHeader = () => {
  const classes = useStyles();
  const configParams = getConfig();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const emailAddress = getCookie("email");
  const userFullName = `${getCookie("firstName")} ${getCookie("lastName")}`;

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const getInitials = (value: string) => {
    let names = value.split(" "),
      initials = names[0]?.substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names?.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const singOut = () => {
    deleteAllCookies();
    window.location.href = configParams.platformLogOutUrl;
  };

  const switchToPlatform = () => {
    window.location.href = configParams.platFormAppUrl;
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <AppBar color="default" elevation={0}>
      <Toolbar>
        <Link to="/">
          <ToorakLogo />
        </Link>
        <Box flexGrow={1} />
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            endIcon={<ArrowDropDownIcon />}
            className={classes.root}
          >
            <span className={classes.appName}>
              Box Due Diligence Report Monitoring
            </span>
            <Avatar classes={{ root: classes.avatarRoot }}>
              {getInitials(userFullName) || "JD"}
            </Avatar>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
          >
            <Paper
              classes={{
                root: classes.menuRoot
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuList}
                >
                  <Box display="flex" className={classes.avatarWrap}>
                    <Avatar classes={{ root: classes.avatarRoot }}>
                      {getInitials(userFullName)}
                    </Avatar>
                    <Box>
                      <Typography className="fw-700">{userFullName}</Typography>
                      <Typography className="fs-10">{emailAddress}</Typography>
                    </Box>
                  </Box>
                  {isLATDDR() && (
                    <MenuItem
                      onClick={switchToPlatform}
                      className={classes.signOut}
                    >
                      <ApplicationTilesIcon />
                      <Typography>Toorak Connect</Typography>
                    </MenuItem>
                  )}

                  <MenuItem onClick={singOut} className={classes.signOut}>
                    <SignOutIcon />
                    <Typography>Signout</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
