import React, { useMemo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles({
  ageingIndicatorWrap: {
    gap: "15px"
  },
  ageingText: {
    width: 50
  },
  ageingIndicator: {
    width: 12,
    height: 12,
    display: "block",
    borderRadius: "50%"
  }
});

interface AgeingIndicatorProp {
  ageingStart: any;
  status: string;
  resolvedAt: any;
  resolvedBy?: string;
}

enum Color {
  Yellow = "#ffbf00", // 0-2 hours
  Orange = "#ff9c00", // 2-4 hours
  Red = "#ff2825", // Above 4 hours
  Green = "#00d283" // status: "resolved"
}

const getAgeingIndicator = (startTime: any, endTime: any, status: string) => {
  let color = Color.Green;
  const time = endTime.diff(startTime, "minutes");
  if (status !== "True") {
    if (time < 120) {
      color = Color.Yellow;
    } else if (time >= 120 && time <= 240) {
      color = Color.Orange;
    } else if (time > 240) {
      color = Color.Red;
    }
  }
  return color;
};

const AgeingIndicator = (props: AgeingIndicatorProp) => {
  const { ageingStart, status, resolvedAt, resolvedBy } = props;
  const classes = useStyles();

  const ageing = useMemo(() => {
    const startTime = moment(new Date(ageingStart));
    let endTime = resolvedAt ? moment(new Date(resolvedAt)) : moment();
    endTime = resolvedBy === "Resolved by System" ? startTime : endTime;
    const diff = endTime.diff(startTime, "milliseconds");
    const temp = moment.duration(diff, "milliseconds");
    let ageingVal = "";
    if (Math.floor(temp.asDays())) {
      ageingVal = `${Math.floor(temp.asDays())}d ${temp.hours()}h`;
    } else if (temp.hours()) {
      ageingVal = `${temp.hours()}h ${temp.minutes()}m`;
    } else if (temp.minutes()) {
      ageingVal = `${temp.minutes()}m ${temp.seconds()}s`;
    } else {
      ageingVal = `${temp.seconds()}s`;
    }
    return ageingVal;
  }, [ageingStart, resolvedAt, resolvedBy]);

  const ageingIndicator = useMemo(() => {
    const startTime = moment.utc(ageingStart);
    let endTime = resolvedAt ? moment.utc(resolvedAt) : moment.utc();
    endTime = resolvedBy === "Resolved by System" ? startTime : endTime;
    return {
      backgroundColor: getAgeingIndicator(startTime, endTime, status)
    };
  }, [ageingStart, resolvedAt, status, resolvedBy]);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.ageingIndicatorWrap}
    >
      <span className={classes.ageingText}>{ageing}</span>
      <span className={classes.ageingIndicator} style={ageingIndicator}></span>
    </Box>
  );
};

export default AgeingIndicator;
