import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClickAwayListener,
  Paper,
  Popper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Box,
  IconButton,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";

import { FilterFunnelIcon } from "../icon/FilterFunnelIcon";
import { updateBoxTableSelectedFilters } from "../views/dashboard/dashboardReport.action";
import { RootState } from "../rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid rgb(255 255 255 / 22%)",
      backgroundColor: "rgb(15 14 14 / 22%)",
      color: "#fff",
      fontSize: 10,
      display: "flex",
      textAlign: "left",
      borderRadius: 8
    },
    menuRoot: {
      width: 200,
      marginTop: 22
    },
    menuList: {
      padding: 0
    },
    listIconRoot: {
      minWidth: 30,
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18
      }
    },
    zeroState: {
      padding: 10
    },
    ageingIndicatorWrap: {
      gap: "10px"
    },
    ageingIndicator: {
      width: 12,
      height: 12,
      display: "block",
      borderRadius: "50%"
    }
  })
);

enum Color {
  Yellow = "#ffbf00", // 0-2 hours
  Orange = "#ff9c00", // 2-4 hours
  Red = "#ff2825", // Above 4 hours
  Green = "#00d283" // status: "resolved"
}

const AgeingFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const boxTablefilterConfig = useSelector<RootState, any>(
    state => state.DashBoardReportStore.boxTablefilterConfig
  );

  const dropdownValue = [
    { key: "yellow", colorCode: Color.Yellow, name: "0 - 2 hours" },
    { key: "orange", colorCode: Color.Orange, name: "2 - 4 hours" },
    { key: "red", colorCode: Color.Red, name: "Above 4 hours" },
    { key: "green", colorCode: Color.Green, name: "Resolved" }
  ];

  const handleToggle = () => {
    setOpen(open => !open);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const checked = useMemo(
    () => boxTablefilterConfig?.conditionProcessEndTime ?? [],
    [boxTablefilterConfig]
  );

  const handleCheckBoxToggle = (value: any) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked: any = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const updatedSelectedFiltersConfig = {
      ...boxTablefilterConfig,
      conditionProcessEndTime: newChecked
    };
    dispatch(updateBoxTableSelectedFilters(updatedSelectedFiltersConfig));
  };

  return (
    <>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FilterFunnelIcon filled={checked.length > 0} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        <Paper
          classes={{
            root: classes.menuRoot
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <List onKeyDown={handleListKeyDown} className={classes.menuList}>
              {dropdownValue?.map((value: any) => {
                return (
                  <ListItem
                    key={value.key}
                    onClick={handleCheckBoxToggle(value.key)}
                    disableGutters
                    dense
                    button
                  >
                    <ListItemIcon
                      classes={{
                        root: classes.listIconRoot
                      }}
                    >
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={checked.indexOf(value.key) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.ageingIndicatorWrap}
                    >
                      <Box>
                        <span
                          className={classes.ageingIndicator}
                          style={{ backgroundColor: value.colorCode }}
                        ></span>
                      </Box>
                      <ListItemText primary={value.name} />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default AgeingFilter;
