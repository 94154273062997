/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, createStore, applyMiddleware } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { enableMapSet } from "immer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { History } from "history";
import {
  LoaderAndExceptionsStore,
  LoaderAndExceptionReducer
} from "./helper/loaderAndException/loaderAndException.reducer";

import {
  DashBoardReportStore,
  DashBoardReportReducer
} from "./views/dashboard/dashboardReport.reducer";

enableMapSet();

export interface RootState {
  LoaderAndExceptionsStore: LoaderAndExceptionsStore;
  DashBoardReportStore: DashBoardReportStore;
  router: any;
}

const rootReducer = (history: History) => {
  return combineReducers<RootState>({
    LoaderAndExceptionsStore: LoaderAndExceptionReducer,
    DashBoardReportStore: DashBoardReportReducer,
    router: connectRouter(history)
  });
};

export const rootStore = (history: History) => {
  const middleware = [thunk, routerMiddleware(history)];

  return createStore(
    rootReducer(history),
    {},
    composeWithDevTools(applyMiddleware(...middleware))
  );
};
