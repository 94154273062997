import { publicClient } from "@toorak/tc-common-fe-sdk";
import { ObjectType } from "../helper/common";
import { getConfig } from "../helper/config";

const get = (url: string) => {
    return new Promise((resolve, reject) => {
        publicClient
            .get(url)
            .then((res: any) => {
                resolve(res);
            })
            .catch(reject);
    });
}

export function getBoxFileDetails(fileID: string) {
    const url = `${getConfig().apiUrl}/conditionsmonitoringservice/${fileID}`;
    return get(url);
}

export function getRoleDetails(emailAddress: string) {
    const url = `${getConfig().apiUrl}/customer/${emailAddress}`;
    return get(url);
}

export function getCustomerRoleDetails(partyId: string) {
    const url = `${getConfig().apiUrl}/customer/${partyId}/roles`;
    return get(url);
}

export function getOrganizationDetails(accountId: string) {
    const url = `${getConfig().apiUrl}/customer/${accountId}`;
    return get(url);
}

export function getUserRoles(accountId: string, partyId: string) {
    const url = `${getConfig().apiUrl}/access/person/${partyId}/access?accountId=${accountId}&includePrivileges=true`;
    return get(url);
}

export function getFilterData(columnName: string, bucketKey: string) {
    const url = `${getConfig().apiUrl}/search/${getConfig().prefix}_exception_process_tracker/_search`;
    let requestBody = {
        query: {
            bool: {
                must: []
            }
        },
        aggs: {
            [bucketKey]: {
                terms: {
                    field: `${columnName}`,
                    size: 100
                }
            }
        },
        from: 0,
        size: 10
    };
    return new Promise((resolve, reject) => {
        publicClient
            .post(url, requestBody)
            .then((res: any) => {
                resolve(res);
            })
            .catch(reject);
    });
}

const boxFilterModel = (data: []) => {
    return {
        query: {
            bool: {
                must: [
                    ...data
                ]
            }
        }
    }
}

const boxSortModel = (data: ObjectType) => {
    let temp = { ...data };

    if (data["ageing"]) {
        return {
            sort: {
                "_script": {
                    "type": "number",
                    "script": {
                        "source": "if(doc['resolvedAt'].size() != 0) return (doc['resolvedAt'].value.millis - doc['conditionProcessEndTime'].value.millis)/1000; else return (new Date().getTime() - doc['conditionProcessEndTime'].value.millis)/1000"
                    },
                    "order": data["ageing"]
                }
            }
        }
    }

    return {
        sort: [
            {
                ...temp
            }
        ]
    }
}

const boxSearchModel = (columns: string[], searchVal: string, body: any) => {
    const query = {
        filter: {
            script: {
                script: {
                    source:
                        "for(x in params.searchFields){if(doc[x].size()>0 && doc[x].value.toLowerCase().contains(params.searchText.toLowerCase())){return true;}} return false;",
                    lang: "painless",
                    params: {
                        searchText: searchVal,
                        searchFields: columns
                    }
                }
            }
        }
    }
    return {
        ...body,
        query: {
            ...body.query,
            bool: {
                ...body.query.bool,
                ...query
            }
        }
    }
}

function createAgeingQuery(body: any, filterValue: any = []) {
    const shouldArray: any = [];
    const mustNotArray: any = [];
    const map: ObjectType = {
        "green": { exists: { field: "resolvedAt" } },
        "red": { range: { conditionProcessEndTime: { lte: "now-4h" } } },
        "orange": { range: { conditionProcessEndTime: { lte: "now-2h", gte: "now-4h" } } },
        "yellow": { range: { conditionProcessEndTime: { gte: "now-2h" } } }
    }

    if (!filterValue.includes("green") && filterValue.length > 0) {
        mustNotArray.push({
            exists: { field: "resolvedAt" }
        });
    }

    filterValue.forEach((item: any) => {
        const must = {
            bool: {
                must: [map[item]],
                must_not: mustNotArray
            }
        }
        shouldArray.push(must)
    });

    if (shouldArray.length === 0 && mustNotArray.length === 0) return body

    return {
        ...body,
        query: {
            ...body.query,
            bool: {
                ...body.query.bool,
                must: [
                    ...body.query.bool.must,
                    {
                        bool: {
                            should: shouldArray
                        }
                    }
                ]

            }

        }
    }
}

function createSearchQuery(body: any, options: ObjectType = {}) {
    const allColumn = [
        "fileName.raw",
        "status.raw",
        "originator.accountName.raw",
        "originatorName.raw"
    ];
    const searchColumn = Object.keys(options)?.[0];
    if (!searchColumn) return body;
    const searchval = options[searchColumn];
    if (!searchval) return body;
    const columns = searchColumn === "all" ? allColumn : [searchColumn];
    return boxSearchModel(columns, searchval, body);
}

function createFilterQuery(model: Function, options: ObjectType = {}) {
    const keys = Object.keys(options);
    let validQuery = false;
    let queryObject: any = [];
    keys.forEach(key => {
        if (options[key].length) {
            queryObject.push({ terms: { [key]: options[key] } });
            validQuery = true;
        }
    })
    return validQuery ? model(queryObject) : null;
}

function createSortQuery(model: Function, options: ObjectType = {}) {
    const keys = Object.keys(options);
    let validQuery = false;
    let queryObject: any = {};
    keys.forEach(key => {
        if (options[key]) {
            queryObject[key] = options[key];
            validQuery = true;
        }
    })
    return validQuery ? model(queryObject) : null;
}

export function getBoxFileList(filterOptions: ObjectType, sortOptions: ObjectType, searchOptions: ObjectType, index: number = 0) {
    const url = `${getConfig().apiUrl}/search/${getConfig().prefix}_exception_process_tracker/_search`;
    let requestBody = {
        query: {
            bool: {
                must: []
            }
        },
        aggs: {
            errorFiles: {
                terms: {
                    field: "errorResolved.raw",
                    missing: "false"
                }
            }
        },
        _source: ["*"],
        from: index,
        size: 15
    };

    const filtersWithoutAgeing = { ...filterOptions };
    delete filtersWithoutAgeing.conditionProcessEndTime

    const filterQuery = createFilterQuery(boxFilterModel, filtersWithoutAgeing);
    if (filterQuery) {
        requestBody = { ...requestBody, ...filterQuery };
    }

    const sortQuery = createSortQuery(boxSortModel, sortOptions);
    if (sortQuery) {
        requestBody = { ...requestBody, ...sortQuery };
    }

    const searchQuery = createSearchQuery(requestBody, searchOptions);

    const finalQueryWithAging = createAgeingQuery(searchQuery, filterOptions?.conditionProcessEndTime);

    return new Promise((resolve, reject) => {
        publicClient
            .post(url, finalQueryWithAging)
            .then((res: any) => {
                resolve(res);
            })
            .catch(reject);
    });

}

export function resolveFileErrors(data: any) {
    const url = `${getConfig().apiUrl}/conditionsmonitoringservice/resolve`;
    return new Promise((resolve, reject) => {
        publicClient
            .post(url, data)
            .then((res: any) => {
                resolve(res);
            })
            .catch(reject);
    });
}

export const getToken = (body: any, headers: any) => {
    return new Promise((resolve, reject) => {
        publicClient
            .post(`${getConfig()?.tokenUrl}/token`, body, headers).then((res: any) => {
                resolve(res);
            })
            .catch(reject);
    });
};