import { produce } from "immer";
import {
  GET_BOX_REPORT_MONITORING_LIST,
  UPDATE_TOTAL_ERROR_FILE,
  UPDATE_TOTAL_FILE,
  UPDATE_BOX_TABLE_FILTERS,
  UPDATE_BOX_TABLE_SORT_OPTIONS,
  UPDATE_BOX_TABLE_SEARCH_OPTIONS
} from "./dashboardReport.action";

export interface DashBoardReportStore {
  boxReportMonitoringResult: any[];
  totalFile: string;
  totalErrorFile: string;
  boxTablefilterConfig?: {};
  boxTableSortConfig?: {};
  boxTableSearchConfig?: {};
}

const initialState: DashBoardReportStore = {
  boxReportMonitoringResult: [],
  totalFile: "0",
  totalErrorFile: "0",
  boxTablefilterConfig: undefined,
  boxTableSortConfig: { conditionProcessEndTime: "desc" },
  boxTableSearchConfig: undefined
};

export const DashBoardReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_BOX_REPORT_MONITORING_LIST: {
      const nextState = produce(state, draftState => {
        draftState.boxReportMonitoringResult = action.payload;
      });
      return nextState;
    }
    case UPDATE_TOTAL_FILE: {
      const nextState = produce(state, draftState => {
        draftState.totalFile = action.payload;
      });
      return nextState;
    }
    case UPDATE_TOTAL_ERROR_FILE: {
      const nextState = produce(state, draftState => {
        draftState.totalErrorFile = action.payload;
      });
      return nextState;
    }
    case UPDATE_BOX_TABLE_FILTERS: {
      const nextState = produce(state, draftState => {
        draftState.boxTablefilterConfig = action.payload;
      });
      return nextState;
    }
    case UPDATE_BOX_TABLE_SORT_OPTIONS: {
      const nextState = produce(state, draftState => {
        draftState.boxTableSortConfig = action.payload;
      });
      return nextState;
    }
    case UPDATE_BOX_TABLE_SEARCH_OPTIONS: {
      const nextState = produce(state, draftState => {
        draftState.boxTableSearchConfig = action.payload;
      });
      return nextState;
    }
    default:
      return state;
  }
};
