import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  InputBase,
  InputAdornment
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #e2e4f1"
    },
    searchInput: {
      backgroundColor: "#f7fafc",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      border: "1px solid #e9ecef",
      borderRadius: 30,
      width: "100%"
    },
    searchIcon: {
      padding: 8
    },
    totalCountWrap: {
      display: "flex",
      marginTop: 8
    },
    divider: {
      height: 16,
      margin: "0 6px",
      width: 2,
      backgroundColor: "#979797"
    },
    clearIcon: {
      backgroundColor: "transparent",
      border: "none"
    }
  })
);

interface SearchFilterProps {
  columnName: "all" | string;
  onSearch?: any;
  searchValue?: string;
}

const SearchFilter = (props: SearchFilterProps) => {
  const { columnName, onSearch = () => {/*defaultValue */ }, searchValue = "" } = props;
  const classes = useStyles();
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setSearchVal(searchValue);
  }, [searchValue])

  const handleSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Enter") {
      return;
    }
      onSearch({ [columnName]: searchVal })
  };

  const clearSearch = () => {
    setSearchVal("");
    onSearch({ [columnName]: "" })
  };

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <InputBase
        placeholder="Search here"
        className={classes.searchInput}
        startAdornment={<SearchIcon className={classes.searchIcon} />}
        onChange={(e) => setSearchVal(e.target.value)}
        onKeyPress={handleSearch}
        value={searchVal}
        endAdornment={
          <InputAdornment
            position="end"
            component="button"
            className={classes.clearIcon}
          >
            {searchVal !== "" && (
              <ClearIcon className={classes.searchIcon} onClick={clearSearch} />
            )}
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default SearchFilter;
