import React from 'react';
import { Link as MuiLink } from "@material-ui/core"

interface LinkProps {
    href: string;
    title: string;
    val?: string;
}

export default function Link(props: LinkProps) {
    const { href, title, val } = props;
    return <MuiLink
        href={href}
        target="_blank"
        rel="noopener"
        underline="none"
        className="custom-link"
        title={title}
    >
        {val || title}
    </MuiLink>
}