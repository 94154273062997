import React from "react";

export const FilterSortIcon = () => {
  return (
    <svg className="App-SvgIcon" viewBox="0 0 14 14">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path d="M0 0h16v16H0z" />
        <path d="M0 0h16v16H0z" />
        <g fill="#32325D" fillRule="nonzero">
          <path d="M11.286 3.475c.136 0 .267.05.364.14l3.086 2.857c.13.12.18.296.133.46a.497.497 0 01-.364.338.544.544 0 01-.498-.123L11.8 5.1l.001 8.375c0 .263-.23.476-.514.476-.284 0-.515-.213-.515-.476V5.101L8.564 7.147a.544.544 0 01-.497.123.497.497 0 01-.364-.337.453.453 0 01.133-.46l3.086-2.858a.536.536 0 01.364-.14z" />
          <path d="M1.664 10.282a.536.536 0 01.365-.14c.136 0 .267.05.364.14l2.721 2.52 2.722-2.52c.13-.12.32-.168.497-.123a.497.497 0 01.364.337.453.453 0 01-.133.46l-3.086 2.857a.536.536 0 01-.364.14.536.536 0 01-.364-.14l-3.086-2.857a.46.46 0 01-.15-.337.46.46 0 01.15-.337z" />
          <path d="M5.114 3.476c.284 0 .515.213.515.476v8.572c0 .263-.23.476-.515.476-.284 0-.514-.213-.514-.476V3.952c0-.263.23-.476.514-.476z" />
        </g>
      </g>
    </svg>
  );
};
