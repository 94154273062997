import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  IconButton,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";

import { FilterFunnelIcon } from "../icon/FilterFunnelIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid rgb(255 255 255 / 22%)",
      backgroundColor: "rgb(15 14 14 / 22%)",
      color: "#fff",
      fontSize: 10,
      display: "flex",
      textAlign: "left",
      borderRadius: 8
    },
    menuRoot: {
      width: 200,
      marginTop: 22
    },
    menuList: {
      padding: 0
    },
    listIconRoot: {
      minWidth: 30,
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18
      }
    },
    zeroState: {
      padding: 10
    }
  })
);

interface ListOptions {
  id?: string;
  key: string;
}
interface ColumnTypeFilterProps {
  columnName: string;
  isLoading?: boolean;
  handleFilterToggle?: Function;
  handleCheckBoxChange?: Function;
  checked?: string[];
  options?: ListOptions[];
}

const ColumnTypeFilter = (props: ColumnTypeFilterProps) => {
  const {
    columnName,
    isLoading,
    checked = [],
    options = [],
    handleFilterToggle = () => {
      /*defaultValue */
    },
    handleCheckBoxChange = () => {
      /*defaultValue */
    }
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = React.useRef(open); // return focus to the button when we transitioned from !open -> open

  const sortedOptions = useMemo(() => {
    return options?.sort((a, b) => {
      const temp1 = a.key.toLowerCase() || "unresolved";
      const temp2 = b.key.toLowerCase() || "unresolved";
      if (temp1 < temp2) {
        return -1;
      }
      if (temp1 > temp2) {
        return 1;
      }
      return 0;
    });
  }, [options]);

  const handleToggle = () => {
    setOpen(open => !open);
    handleFilterToggle(columnName);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleCheckBoxToggle = (e: any) => {
    const val = e.currentTarget.id;
    const isChecked = checked.includes(val);
    let newChecked = [...checked];

    if (isChecked) {
      newChecked = newChecked.filter(i => i !== val);
    } else {
      newChecked.push(val);
    }
    handleCheckBoxChange({ [columnName]: newChecked });
  };

  return (
    <>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FilterFunnelIcon filled={checked.length > 0} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        <Paper
          classes={{
            root: classes.menuRoot
          }}
        >
          {isLoading && (
            <Typography
              classes={{
                root: classes.zeroState
              }}
            >
              Fetching Results
            </Typography>
          )}

          {!isLoading && (
            <ClickAwayListener onClickAway={handleClose}>
              <List onKeyDown={handleListKeyDown} className={classes.menuList}>
                {sortedOptions?.map((value: ListOptions) => {
                  return (
                    <ListItem
                      key={value.key}
                      onClick={handleCheckBoxToggle}
                      disableGutters
                      dense
                      button
                      id={value.key}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.listIconRoot
                        }}
                      >
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={checked?.includes(value.key)}
                          tabIndex={-1}
                          disableRipple
                        />
                        {/* <input
                          type="checkbox"
                          checked={checked.indexOf(value.key) !== -1}
                        ></input> */}
                      </ListItemIcon>
                      <ListItemText primary={value.key || "Unresolved"} />
                    </ListItem>
                  );
                })}
              </List>
            </ClickAwayListener>
          )}
        </Paper>
      </Popper>
    </>
  );
};

export default ColumnTypeFilter;
