export interface Config {
  apiUrl: string;
  environment: string;
  prefix: string;
  tokenUrl: string;
  platformLogOutUrl: string;
  platFormAppUrl: string;
}

const baseConfig: Config = {
  apiUrl: "https://api-dev.toorakcapital.info",
  environment: "local",
  prefix: "dev",
  tokenUrl: "https://auth-dev.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "http://localhost:3000/logout",
  platFormAppUrl: "http://localhost:3000/loans/list?tab=0"
};

const localConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-dev.toorakcapital.info",
  environment: "local",
  prefix: "dev",
  tokenUrl: "https://auth-dev.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "http://localhost:3000/logout",
  platFormAppUrl: "http://localhost:3000/loans/list?tab=0"
};

const devConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-dev.toorakcapital.info",
  environment: "development",
  prefix: "dev",
  tokenUrl: "https://auth-dev.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://dev.toorakcapital.info/logout",
  platFormAppUrl: "https://dev.toorakcapital.info/loans/list?tab=0"
};

const qaConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-qa.toorakcapital.info",
  environment: "qa",
  prefix: "qa",
  tokenUrl: "https://auth-qa.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://qa.toorakcapital.info/logout",
  platFormAppUrl: "https://qa.toorakcapital.info/loans/list?tab=0"
};

const testConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-test.toorakcapital.info",
  environment: "test",
  prefix: "test",
  tokenUrl: "https://auth-test.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://test.toorakcapital.info/logout",
  platFormAppUrl: "https://test.toorakcapital.info/loans/list?tab=0"
};

const prodConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api.toorakcapital.info",
  environment: "production",
  prefix: "prod",
  tokenUrl: "https://auth-gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://www.toorakcapital.info/logout",
  platFormAppUrl: "https://www.toorakcapital.info/loans/list?tab=0"
};

const gcpProdConfig: Config = {
  ...baseConfig,
  apiUrl: "https://gcp-api.toorakcapital.info",
  environment: "production-gcp",
  prefix: "prod",
  tokenUrl: "https://auth-gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://www.gcp.toorakcapital.info/logout",
  platFormAppUrl: "https://www.gcp.toorakcapital.info/loans/list?tab=0"
};

const uatConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-uat.toorakcapital.info",
  environment: "uat",
  prefix: "uat",
  tokenUrl: "https://auth-uat.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://uat.toorakcapital.info/logout",
  platFormAppUrl: "https://uat.toorakcapital.info/loans/list?tab=0"
};

const stageConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-stage.toorakcapital.info",
  environment: "stage",
  prefix: "stage",
  tokenUrl: "https://auth-qa.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  platformLogOutUrl: "https://stage.toorakcapital.info/logout",
  platFormAppUrl: "https://stage.toorakcapital.info/loans/list?tab=0"
};

export const getConfig: () => Config = () => {
  switch (window.location.hostname) {
    case "localhost":
      return localConfig;
    case "monitoring-dev.toorakcapital.info":
      return devConfig;
    case "monitoring-qa.toorakcapital.info":
      return qaConfig;
    case "monitoring-test.toorakcapital.info":
      return testConfig;
    case "www.monitoring.toorakcapital.info":
      return prodConfig;
    case "monitoring.toorakcapital.info":
      return prodConfig;
    case "monitoring-uat.toorakcapital.info":
      return uatConfig;
    case "monitoring-stage.toorakcapital.info":
      return stageConfig;
    case "gcp-monitoring.toorakcapital.info":
      return gcpProdConfig;
    default:
      return devConfig;
  }
};