/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { produce } from "immer";
import {
  SHOW_LOADER,
  HIDE_LOADER
  // STORE_API_ERROR,
  // CLEAR_ERROR
} from "./loaderAndException.action";

export interface LoaderAndExceptionsStore {
  loaderState: any;
  apiErrors: any[];
}
const initialState: LoaderAndExceptionsStore = {
  loaderState: new Map(),
  apiErrors: []
};

export const LoaderAndExceptionReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SHOW_LOADER: {
      const { loaderType } = action.payload;
      const nextState = produce(state, draftState => {
        let updated = new Map(draftState.loaderState);
        updated.set(loaderType, true);
        draftState.loaderState = updated;
      });
      return nextState;
    }

    case HIDE_LOADER: {
      const { loaderType } = action.payload;
      const nextState = produce(state, draftState => {
        let updated = new Map(draftState.loaderState);
        updated.delete(loaderType);
        draftState.loaderState = updated;
      });
      return nextState;
    }

    // case STORE_API_ERROR: {
    //   const { errorConfigToSave } = action.payload;
    //   const nextState = produce(state, (draftState) => {
    //     if (
    //       errorConfigToSave.errorData?.config?.errorConfig
    //         ?.dontSkipIfAlreadyPresent
    //     ) {
    //       draftState.apiErrors = [...draftState.apiErrors, errorConfigToSave];
    //     } else {
    //       const { apiErrors } = state;
    //       const isErrorAlreadyPresent = apiErrors.find(
    //         (item) =>
    //           item.errorData.config.url ===
    //           errorConfigToSave.errorData?.config?.url
    //       );
    //       if (!isErrorAlreadyPresent) {
    //         draftState.apiErrors = [...draftState.apiErrors, errorConfigToSave];
    //       }
    //     }
    //   });
    //   return nextState;
    // }

    // case CLEAR_ERROR: {
    //   const { errorId, autoClose, closeAll } = action.payload;
    //   const nextState = produce(state, (draftState) => {
    //     if (closeAll) {
    //       draftState.apiErrors = [];
    //     } else {
    //       if (autoClose && draftState.apiErrors.length > 1) {
    //         return;
    //       }
    //       draftState.apiErrors = draftState.apiErrors.filter(
    //         (ele) => ele.id !== errorId
    //       );
    //     }
    //   });
    //   return nextState;
    // }

    default:
      return state;
  }
};
