import React from "react";

export const ToorakLogo = () => {
  return (
    <svg width="233" height="38" viewBox="0 0 233 38">
      <defs>
        <path
          id="prefix__a"
          d="M0.244 0.352L21.648 0.352 21.648 20.769 0.244 20.769z"
        />
        <path id="prefix__c" d="M0.313 0.182L1.313 0.182 1.313 37 0.313 37z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#FFF"
            d="M33.399 18.497s-9.904.767-9.744-8.52c0 0 .06-7.49 9.289-7.606 0 0 8.248-.293 9.267 7.463 0 0 .737 8.358-8.812 8.663M44.983 9.71C43.667-.38 33.007 0 33.007 0 21.08.152 21.002 9.897 21.002 9.897 20.796 21.978 33.595 20.98 33.595 20.98c12.34-.397 11.388-11.27 11.388-11.27M60.398 18.497s-9.903.767-9.743-8.52c0 0 .06-7.49 9.288-7.605 0 0 8.25-.294 9.267 7.463 0 0 .738 8.358-8.812 8.662m11.585-8.786C70.667-.379 60.007.001 60.007.001 48.08.152 48.002 9.898 48.002 9.898c-.206 12.08 12.593 11.083 12.593 11.083C72.935 20.584 71.983 9.71 71.983 9.71M11.406 3.366h.055c-.015.026-.034.043-.055 0zm-2.858 0h.055c-.022.043-.04.026-.055 0zM.123 1.026c-.05 0-.09.004-.123.011v2.341c.032.01.072.014.123.014l8.393-.025L8.51 21h2.978l.004-17.633H20V1L.123 1.025zM79.806 10.321h-1.861V3.358H90.18s3.173 0 3.429 3.326c0 0 .118 3.49-4.332 3.637h-9.471zM99 21l-7.803-8.559c.298-.064 4.18-.961 5.168-3.77 0 0 .766-2.102-.198-4.214-.918-2.014-2.185-3.237-5.685-3.434-.049-.013-.1-.023-.152-.023H75v20H77.945v-8.322h9.856c.011.02.022.039.037.055L95.467 21H99z"
            transform="translate(0 .4) translate(0 7.6)"
          />
          <g transform="translate(0 .4) translate(0 7.6) translate(124)">
            <path
              fill="#FFF"
              d="M20.794 1L16.425 1 2.748 11.379 2.748 1 0 1 0 21.417 2.748 21.417 2.748 14.986 8.776 10.291 17.787 21.417 21.404 21.417 10.994 8.563z"
              mask="url(#prefix__b)"
            />
          </g>
          <path
            fill="#FFF"
            d="M105.989 13.424l5.008-9.842.002-.004 5.007 9.846h-10.017zM123 21l-9.758-18.864c-.012-.024-.03-.044-.044-.067L112.604 1H109.438l-.769 1.38-9.173 17.716L99 21h3.116l2.672-5.217h12.417L119.882 21H123z"
            transform="translate(0 .4) translate(0 7.6)"
          />
        </g>
        <g transform="translate(0 .4) translate(148)">
          <path
            fill="#FEFEFE"
            d="M0.813 0.182L0.813 37"
            mask="url(#prefix__d)"
          />
          <path
            fill="#FEFEFE"
            d="M.438 37L1.188 37 1.188.182.438.182zM5.36 13.199c0-2.543 1.494-4.183 3.81-4.183 1.673 0 2.984.889 3.41 2.267-.404.164-.968.422-.968.422-.27-1.061-1.236-1.757-2.443-1.757-1.653 0-2.725 1.28-2.725 3.251 0 1.988 1.061 3.257 2.73 3.257 1.243 0 2.133-.573 2.438-1.561l1.005.222c-.445 1.461-1.668 2.271-3.448 2.271-2.332 0-3.809-1.623-3.809-4.189M19.157 14.085h2.742l-1.353-3.803h-.035l-1.354 3.803zm3.053.884h-3.364l-.85 2.387H16.89l3.118-8.34h1.043l3.117 8.34h-1.108l-.85-2.387z"
          />
          <g fill="#FEFEFE">
            <path
              d="M32.604 7.5L32.604 1.303 30.1 1.303 30.1.5 36.1.5 36.1 1.303 33.595 1.303 33.595 7.5zM41.1 7.5L41.1.5 41.92.5 45.623 5.996 45.666 5.996 45.666.5 46.5.5 46.5 7.5 45.68 7.5 41.977 2.023 41.934 2.023 41.934 7.5zM57.3 7.5L52.3 7.5 52.3.5 57.3.5 57.3 1.286 53.306 1.286 53.306 3.532 57.093 3.532 57.093 4.307 53.306 4.307 53.306 6.714 57.3 6.714zM73.104 5.57c.118.728.922 1.17 2.077 1.17 1.093 0 1.887-.484 1.887-1.15 0-.568-.447-.945-1.564-1.152l-.892-.164c-1.614-.295-2.31-.91-2.31-1.873 0-1.116 1.17-1.901 2.84-1.901 1.607 0 2.768.808 2.812 1.958H76.95c-.089-.742-.787-1.198-1.835-1.198-1.006 0-1.783.413-1.783 1.104 0 .534.43.901 1.53 1.103l.894.164c1.68.31 2.344.873 2.344 1.832 0 1.252-1.172 2.037-2.976 2.037-1.757 0-2.93-.76-3.024-1.93h1.004zM11.281 4.754h2.637l-1.302-3.192h-.032L11.28 4.754zm2.936.742h-3.234L10.166 7.5H9.1l2.998-7h1.004l2.998 7h-1.066l-.817-2.004zM21.994 3.565V1.188h1.808c.999 0 1.6.442 1.6 1.186 0 .761-.564 1.19-1.556 1.19h-1.852zM26.4 2.348C26.4 1.23 25.423.5 23.932.5H21.1v7h.894V4.248h1.852L25.968 7.5H27.1l-2.256-3.372c.97-.245 1.556-.915 1.556-1.78zM2.495 3.838H1.021V1.276h1.474c1.06 0 1.659.544 1.659 1.47 0 .78-.605 1.092-1.659 1.092M2.737.5H.1v7h.921V4.614h1.704c1.388 0 2.375-.539 2.375-1.868C5.1 1.42 4.123.5 2.737.5M62.994 3.565V1.188h1.808c.999 0 1.6.442 1.6 1.186 0 .761-.564 1.19-1.556 1.19h-1.852zM67.4 2.348C67.4 1.23 66.423.5 64.932.5H62.1v7h.894V4.248h1.852L66.968 7.5H68.1l-2.256-3.372c.97-.245 1.556-.915 1.556-1.78z"
              transform="translate(6 20.182)"
            />
          </g>
          <path
            fill="#FEFEFE"
            d="M40.755 17.261L41.854 17.261 41.854 9.016 40.755 9.016zM49.803 17.261L49.803 9.942 47.026 9.942 47.026 9.016 53.679 9.016 53.679 9.942 50.902 9.942 50.902 17.261zM59.972 14.022h2.856l-1.41-3.756h-.036l-1.41 3.756zm3.18.873H59.65l-.885 2.358H57.61l3.247-8.237h1.087l3.247 8.237h-1.154l-.885-2.358zM75.168 17.253L69.821 17.253 69.821 9.016 70.92 9.016 70.92 16.328 75.168 16.328zM32.193 12.947h-1.758V9.93h1.758c1.264 0 1.978.64 1.978 1.731 0 .917-.565 1.286-1.978 1.286m.287-3.93h-3.143v8.244h1.098v-3.4h2.032c1.655 0 2.832-.634 2.832-2.2 0-1.56-1.165-2.645-2.82-2.645"
          />
        </g>
      </g>
    </svg>
  );
};
